/* eslint-disable @typescript-eslint/no-explicit-any */
import { Application } from '@/models/Entities/Application/Application';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { formatDateTime } from '@/services/formatService';
import store from '@/store';

export async function archiveApplicationCallback(
  applicant: Applicant,
  data: Application,
  index: number,
  sectionHeader: string,
  modalComponent: { open: (message: string) => Promise<void> },
  localStore = store
): Promise<void> {
  try {
    const message =
      'Delete the Application that was created on ' +
      formatDateTime(data.createDateTime) +
      '?';
    await modalComponent.open(message);
    await localStore.dispatch('candidateModule/deleteApplication', {
      applicantId: applicant.id,
      applicationId: data.id
    });
    const applications = await store.dispatch(
      'applicantModule/findApplications',
      { candidateIds: [applicant.id], archived: true }
    );
    const applicationIndex = applications.findIndex(
      (application: Application) => application.id === data.id
    );
    applications.splice(applicationIndex, 1);
    await localStore.dispatch('profileSideNavigationModule/removeEntry', {
      sectionHeader: sectionHeader,
      entryIndex: index
    });
  } catch (e: any) {
    if (e) {
      localStore.dispatch('snackBarModule/enqueue', {
        message:
          e.message ??
          'There was an error removing this application, please try again',
        icon: 'caution'
      });
    }
  }
}
