/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore next */
export default {
  inserted: function (el: HTMLElement, binding: any): void {
    const f = function (evt: Event): void {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  }
};
