/**
 * scrolls to the given id found in the DOM
 *
 * @export
 * @param {string} id
 */
export function scrollToElement(id: string): void {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
