
import { Component, Vue } from 'vue-property-decorator';
import scroll from '@/util/directives/scroll';

@Component<StickyContainerComponent>({
  directives: {
    scroll
  }
})
export default class StickyContainerComponent extends Vue {
  windowScrollTop = 0;
  fromTop = 0;
  height = 'auto';
  width = 'auto';
  resizeHeightObserver!: ResizeObserver;
  resizeWidthObserver!: ResizeObserver;

  $refs!: {
    parentElement: HTMLInputElement;
    contentElement: HTMLInputElement;
  };

  mounted(): void {
    const resizeWidth = () => {
      const el = this.$refs.parentElement;
      if (el) {
        this.width = `${el.getBoundingClientRect().width}px`;
      }
    };
    const resizeHeight = () => {
      const el = this.$refs.contentElement;
      if (el) {
        this.height = `${el.getBoundingClientRect().height}px`;
      }
    };
    const parentElement = this.$refs.parentElement;
    const contentElement = this.$refs.contentElement;
    const conteRect = contentElement.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();
    // Set the initial height and position of sticky container
    this.fromTop = conteRect.top;
    this.height = `${conteRect.height}px`;
    this.width = `${parentRect.width}px`;
    // Resize the height of the sticky area if the content changes in height
    this.resizeHeightObserver = new ResizeObserver(resizeHeight);
    this.resizeHeightObserver.observe(contentElement);
    // Resize the width of the sticky container if the parent element changes width
    this.resizeWidthObserver = new ResizeObserver(resizeWidth);
    this.resizeWidthObserver.observe(contentElement);
    this.resizeWidthObserver.observe(parentElement);
  }

  beforeDestroy(): void {
    const parentElement = this.$refs.parentElement;
    const contentElement = this.$refs.contentElement;
    this.resizeHeightObserver.unobserve(contentElement);
    this.resizeWidthObserver.unobserve(parentElement);
  }
}
