import { scrollToElement } from '@/util';

/**
 * returns a callback to initiate scroll to logic to
 * a specific key
 *
 * @export
 * @param {string} key
 * @returns  {() => void}
 */
export default function generateScrollToCallback(key: string): () => void {
  return () => {
    scrollToElement(key);
  };
}
