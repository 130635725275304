var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { ref: "parentElement", staticClass: "w-full" }, [
    _c("div", {
      staticClass: "mt-8",
      style: {
        "min-height": `${this.height}`,
      },
    }),
    _c(
      "div",
      {
        staticClass: "fixed z-50 w-full bottom-4",
        style: { "min-height": `${this.height}`, width: _vm.width },
      },
      [_c("div", { ref: "contentElement" }, [_vm._t("default")], 2)]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }